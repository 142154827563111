<template>
  <div class=container>
      <table class=table width="73%" border="0" cellspacing="30" cellpadding="0"><tbody><tr>
                  <td height="6795" align="left" valign="top"><div align="center"><font color="#000000">					  </font>
                    <div align="center">                      <font size="6" color="#000099"><b><font face="Verdana, Arial, Helvetica, sans-serif">
                    </font></b></font>
                      <center>
                        <font size="+2">Search Engine </font>
                      </center> 
                      <font color="#000033"><br>
                      </font>
                      <div align="center"><font color="#000033"><br>
                        </font>
                          <table width="95%" height="150" border="0" align="center" bgcolor="FFFFCC">
                            <tbody><tr>
                              <td height="126"><div align="center" class="style3"><font face="Verdana, Arial, Helvetica, sans-serif">Columbia Weather and Illumination Database (WILD) is an extensive database of high quality, calibrated images of a static outdoor scene captured under all seasons, lighting and weather conditions. The two DVDs distributed contain a subset (around <strong>850</strong> images) of this large dataset that includes a wide variety of seasons, lighting and weather changes. For details regarding the database itself, please refer to our paper mentioned on the [&nbsp;<router-link to="/publications">Publications page</router-link>&nbsp;]. This page provides instructions on how to use our matlab search engine to find images of the WILD database according to the stored ground truth data. </font></div></td>
                            </tr>
                          </tbody></table>
                          <font size="6" face="Verdana, Arial, Helvetica, sans-serif"><b><br>
                          </b></font>
                          <table width="100%" border="0">
                            <tbody><tr>
                              <td width="49%"><div align="center"><font face="Verdana, Arial, Helvetica, sans-serif" size="2">[ <router-link to="/repository/WILD">Back to WILD Home</router-link> ]</font></div></td>
                            </tr>
                          </tbody></table>
                          <font size="6" face="Verdana, Arial, Helvetica, sans-serif"><b> <br>
                          </b></font>
                          <table width="95%" border="0" align="center" cellpadding="0">
                            <tbody><tr>
                              <td height="117"><p><font size="2" face="Verdana, Arial, Helvetica, sans-serif"><strong>Software Requirements</strong></font></p>
                                  <p><font size="2" face="Verdana, Arial, Helvetica, sans-serif">&nbsp;&nbsp;The search engine tool was tested on windows NT/2000/XP.<strong> </strong>Basic Matlab (version 6) and Image Processing (version 3.1) toolboxes are needed for full functionality. If <strong>Matlab is not available</strong>, a standalone exe version for a PC (Win32 Console Application) can be used with lower functionality. The Matlab Compiler Version 3.0 was used to compile the Matlab-based search engine into a standalone application.<br>
&nbsp;&nbsp;Note that both the matlab and exe versions of the search engine are included with the Image datasets distributed on 2 DVDs. If you do not have the WILD DVDs, then you can just download the search engine software from [&nbsp;<a href="http://www.cs.columbia.edu/~wild/WILD_Search_Engine.zip">here</a>&nbsp;]. Even without the DVDs you can browse the TYPES of images (based on atmospheric conditions and other [&nbsp;<a href="about_db_main.html#ground">ground truth information</a>&nbsp;]) packaged on the DVDs, using the search engine software. This will help in deciding whether or not the image sets are useful to your research activities.</font></p></td>
                            </tr>
                            <tr>
                              <td height="70"><p><br>
                                      <font size="2" face="Verdana, Arial, Helvetica, sans-serif"><strong>Software Installation Instructions</strong></font></p>
                                  <p>&nbsp;&nbsp;(<font size="2" face="Verdana, Arial, Helvetica, sans-serif">i) To run the matlab version:</font><br>
&nbsp;&nbsp;&nbsp;&nbsp; <font size="2" face="Verdana, Arial, Helvetica, sans-serif">(a) Start Matlab and change the current matlab directory to the directory where the matlab (.m) files and the Image Database are present. If you have a WILD DVD, you can run the search engine off the DVD itself. There is no need to copy the entire database (several giga bytes of data) onto a local directory.</font><br>
                <font size="2" face="Verdana, Arial, Helvetica, sans-serif">&nbsp;&nbsp;&nbsp;&nbsp;(b) From the matlab command prompt, type "search_wild"</font></p>
                                  <p><font size="2" face="Verdana, Arial, Helvetica, sans-serif">&nbsp;&nbsp;(ii) To run the standalone exe version:</font><br>
&nbsp;&nbsp;&nbsp;&nbsp; <font size="2" face="Verdana, Arial, Helvetica, sans-serif">(a) If you have the WILD DVDs, you must set the SYSTEM PATH variable to &lt;DVD_DRIVE_LETTER&gt;:WILD_DVD_Clear\bin\win32 or &lt;DVD_DRIVE_LETTER&gt;:WILD_DVD_BadWeather\bin\win32. If you copy the contents of the DVD to your hard drive location &lt;USER_LOCATION&gt;, then you must set the SYSTEM PATH variable to &lt;USER_LOCATION&gt;:\bin\win32.<br>
&nbsp;&nbsp;&nbsp; </font><font size="2" face="Verdana, Arial, Helvetica, sans-serif">(b) Run either clear_search_wild.exe to browse the clear weather database (DVD1) or weather_search_wild.exe to browse the bad weather database (DVD2).</font></p>
                                  <p><font size="2" face="Verdana, Arial, Helvetica, sans-serif">&nbsp;&nbsp;(iii) If you just want to browse the types of image sets without getting the DVDs, then please download the installable zip file from [ <a href="http://www.cs.columbia.edu/~wild/WILD_Search_Engine.zip">here</a> ] and follow the instructions given in [&nbsp;<a href="1STREADME.txt">1STREADME.txt</a>&nbsp;].</font> </p></td>
                            </tr>
                            <tr>
                              <td><br>
                                  <font size="2" face="Verdana, Arial, Helvetica, sans-serif"><strong>Common Problems and Bugs (To be updated regularly)</strong><br>
            <br>
&nbsp;&nbsp;If the search engine GUI does not appear correctly, please increase the resolution of your display and restart the search engine software. If the standalone application complains that it could not find a dll or an entry point, the SYSTEM PATH to &lt;USR_SPECIFIED_DIR&gt;\bin\win32 is probably not set correctly. Please try to reset the system PATH variable. If the problem persists, please try to remove any matlab paths that may have been set before in the SYSTEM PATH.<br>
            <br>
&nbsp;&nbsp;Please report any other problems to wild@cs.columbia.edu</font></td>
                            </tr>
                            <tr>
                              <td><p><font size="2" face="Verdana, Arial, Helvetica, sans-serif"><strong><br>
        How to use the search engine tool</strong></font></p>
                                  <p><font size="2" face="Verdana, Arial, Helvetica, sans-serif">&nbsp;&nbsp;This section provides step by step instructions on how to use the search engine tool with screen shots of the tool in operation.</font></p></td>
                            </tr>
                            <tr>
                              <td height="5507"><table width="100%" border="0">
                                  <tbody><tr>
                                    <td height="195">&nbsp;</td>
                                    <td><div align="center"><strong><font size="2" face="Verdana, Arial, Helvetica, sans-serif"><br>
                                                <br>
                                                <br>
              STEP 1</font></strong><br>
              <font size="2" face="Verdana, Arial, Helvetica, sans-serif">When search_wild.exe or the matlab file search_wild.m is executed, a small Menu GUI pops up asking user to select either the Clear Weather Database or the Bad Weather Database. Accordingly, the appropriate database contents are loaded into the search engine. Note that the search engine files must be placed in the directory where ClearWeatherDatabase or BadWeatherDatabase exists.<br>
                            <br>
                            <br>
              </font>
              <table width="5%" border="0">
                <tbody><tr>
                  <td><img src="https://cave.cs.columbia.edu/old/software/wild/images/search_engine_selection_screen.jpg" width="164" height="124"></td>
                </tr>
              </tbody></table>
              <font size="2" face="Verdana, Arial, Helvetica, sans-serif"> </font> </div></td>
                                  </tr>
                                  <tr>
                                    <td width="2%" height="829">&nbsp;</td>
                                    <td width="98%"><div align="left"><br>
                                            <br>
                                            <br>
                                            <font size="2" face="Verdana, Arial, Helvetica, sans-serif">
                                            <center>
                                              <strong>STEP 2</strong>
                                            </center>
                                            </font><font size="2" face="Verdana, Arial, Helvetica, sans-serif"> When one of the database buttons in the above step is clicked, the basic user interface for the search engine appears as shown below.<br>
              <br>
                                          </font> </div>
                                        <table width="66%" border="0" align="center">
                                          <tbody><tr>
                                            <td><img src="https://cave.cs.columbia.edu/old/software/wild/images/search_engine_screen1.jpg" width="700" height="655"></td>
                                          </tr>
                                      </tbody></table></td>
                                  </tr>
                                  <tr>
                                    <td height="819">&nbsp;</td>
                                    <td><br>
                                        <br>
                                        <br>
                                        <font size="2" face="Verdana, Arial, Helvetica, sans-serif">
                                        <center>
                                          <strong>STEP 3<br>
                                          </strong> Fill the required entries in the search engine user interface. An example is shown below, where we want to search for images that were taken in clear weather, but under partly cloudy skies, the humidity between 40 and 60 percent, the time of day being between 9 AM and 8 PM. The entries left unfilled can take on any values. <br>
                            <br>
                                        </center>
                                        </font>
                                        <table width="66%" border="0" align="center">
                                          <tbody><tr>
                                            <td><img src="https://cave.cs.columbia.edu/old/software/wild/images/search_engine_screen2.jpg" width="700" height="650"></td>
                                          </tr>
                                      </tbody></table></td>
                                  </tr>
                                  <tr>
                                    <td height="110">&nbsp;</td>
                                    <td><br>
                                        <br>
                                        <br>
                                        <font size="2" face="Verdana, Arial, Helvetica, sans-serif">
                                        <center>
                                          <strong>STEP 4</strong><br>
              To save the settings you have entered, you can provide a filename in the bottom of the GUI as shown below and press the SAVE SETTINGS button. To load the settings again, type in the filename and click on the LOAD SETTINGS button. The extension of the filename should be ".mat". If no extension is provided, then the default extension (.mat) is added. If the filename is given without a path, the file is created in the current directory. When running this software from the DVD, this can result in an error. Therefore, to avoid this problem, please specify the full path where the file can be created.<br>
                            <br>
                                        </center>
                                        </font>
                                        <table width="66%" border="0" align="center">
                                          <tbody><tr>
                                            <td><img src="https://cave.cs.columbia.edu/old/software/wild/images/search_engine_screen3.jpg" width="700" height="650"></td>
                                          </tr>
                                      </tbody></table></td>
                                  </tr>
                                  <tr>
                                    <td height="135">&nbsp;</td>
                                    <td><font size="2" face="Verdana, Arial, Helvetica, sans-serif"><br>
                                          <br>
                                          <br>
                                      </font><font size="2" face="Verdana, Arial, Helvetica, sans-serif">
                                      <center>
                                        <strong>STEP 5</strong><br>
              Click on the "Search Database" button to get the search results. This pops up another user interface that contains the list of images that satisfied your criteria. If the search criteria are incorrect, then an appropriate error message is posted on the results GUI. If the search criteria are too specific (for eg., all images taken when the humidity level was between 35 and 40 percent), there might be very few or no images that match the search criteria exactly. Try again by relaxing some of the requirements. <br>
              The locations/paths of the images are shown in this GUI. The images are stored according to their capture time. For example, the path D:\WILD_DVD_Clear\WeatherDatabase\2002\Jun\10\21 denotes that the image was captured at 9PM, on 10th June 2002. <br>
                            <br>
                                      </center>
                                      </font>
                                      <table width="62%" border="0" align="center">
                                        <tbody><tr>
                                          <td><img src="https://cave.cs.columbia.edu/old/software/wild/images/search_engine_screen4.jpg" width="700" height="701"></td>
                                        </tr>
                                    </tbody></table></td>
                                  </tr>
                                  <tr>
                                    <td height="696">&nbsp;</td>
                                    <td><font size="2" face="Verdana, Arial, Helvetica, sans-serif">
                                      <center>
                                      <p><strong>
                <br>
                <br>
                <br>
                STEP 6</strong><br>
                The results GUI can be used to visualize the images that matched your search criteria. The images in the database are radiometrically linearized (linear radiometric response function) and tend to be dark. To visualize the images, input the gamma value (less than 1 means brighter), select the images to view, and hit the "View Selected Images" button as shown in the screen shot below.<br>
                                <strong>Note that the application of gamma is only for visualization and does not change the image stored in the database. </strong><br>
                                <br>
                                        </p>
                                    </center>
                                      </font>
                                        <table width="98%" border="0">
                                          <tbody><tr>
                                            <td><img src="https://cave.cs.columbia.edu/old/software/wild/images/search_engine_screen5.jpg" width="700" height="477"></td>
                                          </tr>
                                    </tbody></table></td>
                                  </tr>
                                  <tr>
                                    <td height="145">&nbsp;</td>
                                    <td><font size="2" face="Verdana, Arial, Helvetica, sans-serif">
                                      <center>
                                        <strong><br>
                                        <br>
                                        <br>
              STEP 7</strong><br>
              Another advanced option is given to users who wish to apply their own matlab function to selected images. This function should be of the form: <br>
              outputImage = function(inputImage)<br>
              For example, to visualize only a gray scale version of the image, then "rgb2gray" can be typed in the space provided (see the screen shot below.) <br>
              NOTE: This search engine is not a tool to process images but a few options such as gamma correction are provided for visualization purposes only. Users <strong>cannot apply their own matlab functions using the standalone Win32 console application (.exe) version</strong> of the search engine. Matlab is required for this functionality.<br>
                            <br>
                                      </center>
                                      </font>
                                        <table width="75%" border="0">
                                          <tbody><tr>
                                            <td><img src="https://cave.cs.columbia.edu/old/software/wild/images/search_engine_screen6.jpg" width="700" height="470"></td>
                                          </tr>
                                      </tbody></table></td>
                                  </tr>
                                  <tr>
                                    <td height="116">&nbsp;</td>
                                    <td><font size="2" face="Verdana, Arial, Helvetica, sans-serif"><br>
                                          <br>
                                          <br>
                                          <center>
                                            <strong>STEP 8</strong><br>
              The "View Selected Images" button is used to view images at full resolution (1520 x 1008, 16 bits per pixel per color channel) and thus might take a long time to load and to apply the users' function (gamma or rgb2gray, say). For a faster visualization, an option of viewing pre-computed low resolution thumbnails of the images are provided. The image processing functions gamma, etc) cannot be applied by the user to these thumbnails. The user can select a set of images and view a time lapse video of the thumbnail versions of the selected images. This is helpful in deciding how to prune the image set further. The time lapse video can also be saved as an uncompressed avi file. Refer to figure in Step 6 for details. <br>
              Note that <strong>users cannot save the time lapse videos as AVI files using the standalone Win32 console application (.exe) version</strong> of the search engine. Matlab is required for this functionality.<br>
                                          </center>
                                    </font></td>
                                  </tr>
                                  <tr>
                                    <td height="146">&nbsp;</td>
                                    <td><font size="2" face="Verdana, Arial, Helvetica, sans-serif"><br>
                                          <br>
                                          <br>
                                          <center>
                                            <strong>STEP 9</strong><br>
              The paths to the images that matched the users search criteria can be saved in a text file. <strong>If the filename is given without a path, the file is created in the current directory.</strong> When running this software from the DVD, this can result in an error. Therefore, to avoid this problem, please specify the full path where the file can be created. <br>
              Refer to figure in Step 6.
                                          </center>
                                    </font></td>
                                  </tr>
                              </tbody></table></td>
                            </tr>
                          </tbody></table>
                      </div>
                      <br>
                      <br>
                      <hr width="700" size="0">
                      <br>
</div>
                    <table width="100%" border="0">
  <tbody><tr>
    <td width="49%"><div align="center"><font face="Verdana, Arial, Helvetica, sans-serif" size="2">[ <router-link to="/repository/WILD">Back to WILD Home</router-link> ]</font></div></td>
  </tr>
</tbody></table>
<br>
<br>
<table width="100%" border="0" align="center">
  <tbody><tr>
    <td width="49%"><div align="center"><font color="#000033" size="2" face="Verdana, Arial, Helvetica, sans-serif">Contact: <a href="mailto:wild@cs.columbia.edu">wild@cs.columbia.edu</a></font></div></td>
  </tr>
</tbody></table>
                  </div></td>
                </tr>
        </tbody></table>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:0 auto;
}
</style>